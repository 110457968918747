<template>
  <div
    :class="[
      'm-website-header',
      isPC ? 'z-web' : 'z-phone',
      { 'source-app': header },
      showTab && !isPC ? 'z-fill' : '',
      { 'z-visual': cmsVisual, 'z-fill': top > 0 },
      theme.primaryColor ? theme.primaryColor : 'blue'
    ]"
  >
    <div v-if="isPC" class="g-content f-clearfix">
      <div class="m-logo g-l">
        <a href="/" class="f-clearfix">
          <client-only>
            <template v-if="websiteHost === hostname">
              <svg class="u-logo u-logo-height" aria-hidden="true">
                <use xlink:href="#iconlogo" />
              </svg>
              <svg class="u-logo logo-icon white" aria-hidden="true">
                <use xlink:href="#iconlogobai" />
              </svg>
            </template>
            <template v-else>
              <VisualItem :visualKeys="[logo.visualKeys, logo_2x.visualKeys]" @visual="handleVisual" class-name="logo-item u-logo blue">
                <CMSImage
                  v-if="(logo && logo.value) && (logo_2x && logo_2x.value)"
                  :img="logo ? logo.value : ''"
                  :img2x="logo_2x ? logo_2x.value : ''"
                />
              </VisualItem>
              <VisualItem :visualKeys="[logo_white.visualKeys, logo_white_2x.visualKeys]" @visual="handleVisual" class-name="logo-item u-logo white">
                <CMSImage
                  v-if="(logo_white && logo_white.value) && (logo_white_2x && logo_white_2x.value)"
                  :img="logo_white ? logo_white.value : ''"
                  :img2x="logo_white_2x ? logo_white_2x.value : ''"
                />
              </VisualItem>
            </template>
          </client-only>
        </a>
      </div>
      <VisualItem :visualKeys="[btn.visualKeys, btnLink.visualKeys]" @visual="handleVisual" class-name="g-r">
        <CMSLink
          :link="btnLink.value"
          class-name="u-btn"
        >
          {{ btn.value }}
        </CMSLink>
      </VisualItem>
      <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual" class-name="m-list f-clearfix" tip="编辑列表">
        <VisualItem
          v-for="(item, index) in list.value"
          :key="index"
          :visualKeys="[item.title.visualKeys, item.link.visualKeys]"
          @visual="handleVisual"
          class-name="g-l"
        >
          <CMSLink
            :link="item.link.value"
            :class-name="['m-menu', list.value.length > 4 ? 'm-menu-4' : '', ((path !== '/' && item.link.value.indexOf(path) === 0) || path === item.link.value) ? 'z-selected' : '', path.indexOf('main_activity') === 1 ? 'main-activity' : '']"
          >
            {{ item.title.value }}
          </CMSLink>
        </VisualItem>
      </VisualItem>
    </div>
    <div v-else class="g-content f-clearfix">
      <div class="m-logo g-l">
        <a href="/" class="f-clearfix">
          <client-only>
            <template v-if="websiteHost === hostname">
              <svg class="u-logo logo-icon blue" aria-hidden="true">
                <use xlink:href="#iconshuchuang" />
              </svg>
              <svg class="u-logo logo-icon white" aria-hidden="true">
                <use xlink:href="#iconshuchuang-white" />
              </svg>
            </template>
            <template v-else>
              <VisualItem :visualKeys="[logo_2x_h5.visualKeys, logo_3x_h5.visualKeys]" @visual="handleVisual" class-name="logo-item u-logo blue">
                <CMSImage
                  v-if="(logo_2x_h5 && logo_2x_h5.value) && (logo_3x_h5 && logo_3x_h5.value) || (logo_white && logo_white.value) && (logo_white_2x && logo_white_2x.value)"
                  :img="logo_2x_h5 ? logo_2x_h5.value : logo.value"
                  :img2x="logo_3x_h5 ? logo_3x_h5.value : logo_2x.value"
                />
              </VisualItem>
              <VisualItem :visualKeys="[logo_white_2x_h5.visualKeys, logo_white_3x_h5.visualKeys]" @visual="handleVisual" class-name="logo-item u-logo white">
                <CMSImage
                  v-if="(logo_white_2x_h5 && logo_white_2x_h5.value) && (logo_white_3x_h5 && logo_white_3x_h5.value) || (logo_white && logo_white.value) && (logo_white_2x && logo_white_2x.value)"
                  :img="logo_white_2x_h5 ? logo_white_2x_h5.value : logo.value"
                  :img2x="logo_white_3x_h5 ? logo_white_3x_h5.value : logo_2x.value"
                />
              </VisualItem>
            </template>
          </client-only>
        </a>
      </div>
      <VisualItem :visualKeys="list.visualKeys" @visual="handleVisual" :class-name="['m-list', showTab ? 'm-list-showTab' : '']">
        <div @click="showTab = false" class="closeTab" />
        <div :class="['g-list', showTab ? 'g-list-showTab' : '']">
          <VisualItem
            v-for="(item, index) in list.value"
            :key="index"
            :visualKeys="[item.title.visualKeys, item.link.visualKeys]"
            @visual="handleVisual"
            class-name="g-title"
          >
            <CMSLink
              :link="item.link.value"
              :class-name="['m-menu', ((item.link.value !== '/' && path.indexOf(item.link.value) === 0) || path === item.link.value) ? 'z-selected' : '', path.indexOf('main_activity') === 1 ? 'main-activity' : '']"
            >
              {{ item.title.value }}
            </CMSLink>
          </VisualItem>
        </div>
      </VisualItem>
      <VisualItem v-if="btn.value" :visualKeys="[btn.visualKeys, btnLink.visualKeys]" @visual="handleVisual" class-name="g-r">
        <CMSLink
          :link="btnLink.value"
          class-name="u-btn"
        >
          {{ btn.value }}
        </CMSLink>
      </VisualItem>
      <div v-show="!showTab && (top === 0 && path.length<=2)" @click="showHandal" class="more">
        <img src="/WebsiteHeader/open.svg">
      </div>
      <div v-show="!showTab && (top > 0 || path.length > 2)" @click="showHandal" class="more">
        <img src="/WebsiteHeader/open.svg">
      </div>
      <div v-show="showTab" @click="showHandal" class="more">
        <img src="/WebsiteHeader/close.svg">
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'
import CMSImage from '~/components/common/Image'

const componentKey = 'WebsiteHeader'

export default {
  components: {
    CMSLink,
    VisualItem,
    CMSImage
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    // console.log(componentKey)
    // console.log(temp)

    return {
      ...data,
      top: 0,
      hostname: '',
      websiteHost: process.env.websiteHost,
      showTab: false,
      header: true
    }
  },
  computed: {
    cmsVisual() {
      return this.$store.state.cmsVisual
    },
    path() {
      return this.$nuxt.$route.path
    },
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.reset()
    })

    this.hostname = location.hostname
    this.reset()
    this.header = location.href.indexOf('ua=app') !== -1
    console.log(this.header)
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    },
    reset() {
      this.top = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    },
    showHandal() {
      this.showTab = !this.showTab
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-website-header {
  &.z-phone {
    position: sticky;
    top: 0;
    height: pxtorem(96);
    width: 100%;
    z-index: 999;
    background-color: #fff;
    padding: 0 pxtorem(40);

    .g-content {
      margin: 0 auto;
      height: pxtorem(96);
      position: relative;

      .g-r {
        /* width: pxtorem(240); */
        margin-top: pxtorem(24);
        margin-right: pxtorem(50)
      }

      .more {
        position: absolute;
        top: pxtorem(30);
        right: pxtorem(-16);
        width: pxtorem(70);
        text-align: center;
        height: pxtorem(40);
      }
    }

    .m-logo {
      line-height: pxtorem(84);
      font-size: pxtorem(92);
      width: 4em;

      & > a {
        display: block;
      }

      .logo-item {
        overflow: hidden;

        img {
          max-width: pxtorem(360);
          vertical-align: baseline;
        }
      }

      .logo-icon {
        width: 4em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }

      .u-logo.white {
        display: none;
      }
    }

    .u-btn {
      max-width: pxtorem(168);
      height: pxtorem(50);
      font-size: pxtorem(22);
      line-height: pxtorem(52);
      text-align: center;
      display: block;
      color: #fff;
      background-color: $baseBlue;
      padding: 0 pxtorem(12);
    }

    .m-list {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.298487);
      position: fixed;
      top: pxtorem(96);
      right: 100%;
      bottom: 0;

      .closeTab {
        width: calc(100% - pxtorem(560));
        height: 100%;
        position: fixed;
        bottom: 0;
      }

      .g-list {
        width: calc(100% - pxtorem(190));
        height: 100%;
        background: #fff;
        padding-top: pxtorem(28);
        position: fixed;
        right: pxtorem(-560);
        text-align: center;
        transition: 300ms;

        .g-title {
          line-height: pxtorem(84);
          margin-bottom: pxtorem(28);

          &:nth-child(3) > .main-activity {
            color: $baseBlue;
            background: #f3f6fc;
          }

          .m-menu {
            display: block;
            width: 100%;
            color: #333;
          }

          .z-selected {
            background: #f3f6fc;
            color: $baseBlue;
          }
        }
      }

      .g-list-showTab {
        height: 100%;
        position: fixed;
        right: 0;
      }
    }

    .m-list-showTab {
      position: fixed;
      right: 0;
    }

    &.z-fill {
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      color: #333;

      .m-logo {
        .u-logo {
          &.white {
            display: none;
          }

          &.blue {
            display: block;
          }
        }
      }
    }

    &.source-app {
      display: none;
    }

    &.red {
      .g-list .g-title .z-selected {
        color: $baseRed;
        background-color: #fffafa;
      }

      .u-btn {
        background: $baseRed;
      }

      .g-list .g-title:nth-child(3) > .main-activity {
        color: $baseRed;
        background: #fffafa;
      }
    }
  }

  &.z-web {
    position: fixed;
    top: 0;
    height: 64px;
    width: 100%;
    z-index: 997;
    background: #fff;

    .g-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;

      .u-btn {
        color: #fff;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    .m-logo {
      width: 256px;
      line-height: 64px;
      font-size: 30px;
      height: 64px;
      text-align: left;

      .u-logo-height {
        height: 19px;
      }

      & > a {
        display: block;
      }

      .logo-item {
        overflow: hidden;
        position: relative;
        width: 256px;
        height: 64px;

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .logo-icon {
        width: 256px;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }

      .u-logo.white {
        display: none;
      }
    }

    .m-list {
      display: inline-block;
      line-height: 64px;

      .m-menu {
        margin: 0 37px;
        font-size: 14px;
        color: #333;
        position: relative;
        display: block;

        &.z-selected {
          color: #fff;

          &::after {
            position: absolute;
            content: " ";
            width: 25px;
            height: 3px;
            background-color: #fff;
            left: 50%;
            margin-left: -12px;
            bottom: 3px;
          }
        }
      }
    }

    .u-btn {
      display: block;
      color: #fff;
      background-color: transparent;
      line-height: 36px;
      margin: 13px 0;
      padding: 0 23px;
    }

    &.z-visual {
      top: 61px;
      left: 70px;
      right: 0;
      width: auto;
    }

    &.z-fill {
      background: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      color: #333;

      .m-logo {
        .u-logo {
          &.white {
            display: none;
          }

          &.blue {
            display: block;
          }
        }
      }

      .m-list {
        .m-menu {
          color: #333;

          &.z-selected {
            color: #333;

            &::after {
              background-color: #fff;
            }
          }
        }
      }

      .u-btn {
        color: #333;
      }
    }

    /* 页面风格变为红色时 */
    &.red {
      .m-menu {
        color: #333;
      }

      &.z-fill {
        .m-menu.z-selected {
          color: $baseRed;
        }

        .m-menu.z-selected::after {
          background-color: $baseRed;
        }

        .u-btn {
          &:hover {
            background-color: #c91417;
          }

          &:active {
            background-color: #bf0b0e;
          }
        }
      }

      .m-menu.z-selected {
        color: $baseRed;
      }

      .u-btn {
        color: #fff;
        background-color: $baseRed;
      }

      .m-menu.z-selected::after {
        background-color: $baseRed;
      }

      /* 单独为活动详情页设置导航栏样式 */
      .g-l .g-l:nth-child(2) {
        .main-activity {
          color: $baseRed;

          &::after {
            position: absolute;
            content: " ";
            width: 25px;
            height: 3px;
            background-color: $baseRed;
            left: 50%;
            margin-left: -12px;
            bottom: 3px;
          }
        }
      }
    }

    /* 页面风格变为蓝色时 */
    &.blue {
      .m-menu {
        color: #333;
      }

      &.z-fill {
        .m-menu.z-selected {
          color: $baseBlue;
        }

        .m-menu.z-selected::after {
          background-color: $baseBlue;
        }

        .u-btn {
          &:hover {
            background-color: #325cf3;
          }

          &:active {
            background-color: #2a54e8;
          }
        }
      }

      .m-menu.z-selected {
        color: $baseBlue;
      }

      .u-btn {
        color: #fff;
        background-color: $baseBlue;
      }

      .m-menu.z-selected::after {
        background-color: $baseBlue;
      }

      .g-l .g-l:nth-child(2) {
        .main-activity {
          color: $baseBlue;

          &::after {
            position: absolute;
            content: " ";
            width: 25px;
            height: 3px;
            background-color: $baseBlue;
            left: 50%;
            margin-left: -12px;
            bottom: 3px;
          }
        }
      }
    }
  }
}
</style>
